import React, {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Table, Pagination, Select, Checkbox, Alert } from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";
import TFoot from "components/ui/Table/TFoot";
import { useSelector } from "react-redux";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = (props) => {
  const {
    indeterminate,
    onChange,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    ...rest
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line
  }, [ref, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return (
    <Checkbox
      className="mb-0"
      ref={ref}
      onChange={(_, e) => handleChange(e)}
      {...rest}
    />
  );
};

function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip];
}

const DataTable = forwardRef((props, ref) => {
  const {
    skeletonAvatarColumns,
    columns: columnsProp,
    data,
    footerRow,
    setData,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes,
    skeletonAvatarProps,
    pagingData,
    selectable = false,
    compact = true,
    showPagination = true,
    expanded = {},
    setExpanded = () => {},
  } = props;

  const { pageSize, pageIndex, total } = pagingData;

  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState(null);

  const contentWidth = useSelector((state) => state.theme.contentWidth);
  const [tableWidth, setTableWidth] = useState();
  const [usedColumns, setUsedColumns] = useState([]);
  const [addColumns, setAddColumns] = useState([]);

  useEffect(() => {
    const diff =
      contentWidth -
      columnsProp.reduce((total, currentValue) => {
        return total + currentValue.width;
      }, 0);
    setAddColumns([]);
    if (diff > 0) {
      setAddColumns([
        {
          header: "",
          id: "adjuster",
          align: "center",
          width: diff,
        },
      ]);
    }
  }, [contentWidth, columnsProp]);

  useEffect(() => {
    setUsedColumns(columnsProp.concat(addColumns));
  }, [columnsProp, addColumns]);

  useEffect(() => {
    setTableWidth(
      usedColumns.reduce((total, currentValue) => {
        return total + currentValue.width;
      }, 0)
    );
  }, [usedColumns]);

  const pageSizeOption = useMemo(
    () =>
      pageSizes.map((number) => ({
        value: number,
        label: `${number} / page`,
      })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  useEffect(() => {
    if (Array.isArray(sorting)) {
      console.log("sorting", sorting);
      const sortOrder =
        sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : "";
      const id = sorting.length > 0 ? sorting[0].id : "";
      onSort?.({ order: sortOrder, key: id });
    }
    // eslint-disable-next-line
  }, [sorting]);

  const hasOldColumnMetaKey = usedColumns.some(
    (col) => col.Header || col.accessor || col.Cell
  );

  const finalColumns = useMemo(() => {
    const columns = usedColumns;

    if (selectable) {
      return [
        {
          id: "select",
          header: ({ table }) => (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              onIndeterminateCheckBoxChange={(e) => {
                handleIndeterminateCheckBoxChange(
                  e.target.checked,
                  table.getRowModel().rows
                );
              }}
            />
          ),
          cell: ({ row }) => {
            return (
              <IndeterminateCheckbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onCheckBoxChange={(e) =>
                  handleCheckBoxChange(e.target.checked, row.original)
                }
              />
            );
          },
          width: 60,
        },
        ...columns,
      ];
    }
    return columns;
    // eslint-disable-next-line
  }, [usedColumns, selectable]);

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns: hasOldColumnMetaKey ? [] : finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    state: {
      sorting,
      expanded,
      rowSelection,
    },
    enableRowSelection: selectable,
    getSubRows: (row) => row?.children,
    onRowSelectionChange: setRowSelection,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip age index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      deleteData: (rowIndex) => {
        // Skip age index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old) => old.filter((_, index) => index !== rowIndex));
      },
    },
    // paginateExpandedRows: false
  });

  useEffect(() => {
    setRowSelection({});
  }, [data]);

  const resetSorting = () => {
    table.resetSorting();
  };

  const resetSelected = () => {
    table.toggleAllRowsSelected(false);
  };

  useImperativeHandle(ref, () => ({
    resetSorting,
    resetSelected,
  }));

  if (hasOldColumnMetaKey) {
    const message =
      "You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8";

    if (process.env.NODE_ENV === "development") {
      console.warn(message);
    }

    return <Alert>{message}</Alert>;
  }

  const renderRows = showPagination
    ? // .rows.slice(0, showPagination ? pageSize : pagingData.total)
      table.getRowModel().rows
    : table.getRowModel().rows;

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      <Table
        compact={compact}
        style={{
          width: `${tableWidth}px`,
        }}
      >
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    style={{
                      width: header.column.columnDef.width
                        ? `${header.column.columnDef.width}px`
                        : "",
                    }}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {header.column.columnDef.sortable ? (
                          <div
                            className={classNames(
                              header.column.columnDef.sortable &&
                                header.column.getCanSort() &&
                                "cursor-pointer select-none point",
                              loading && "pointer-events-none"
                            )}
                            style={{
                              textAlign: header.column.columnDef.align
                                ? header.column.columnDef.align
                                : "left",
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header
                                ? header.column.columnDef.header
                                : "No",
                              header.getContext()
                            )}
                            {header.column.getCanSort() && (
                              <Sorter sort={header.column.getIsSorted()} />
                            )}
                          </div>
                        ) : (
                          <div
                            className={classNames(
                              header.column.columnDef.sortable &&
                                header.column.getCanSort() &&
                                "cursor-pointer select-none point",
                              loading && "pointer-events-none"
                            )}
                            style={{
                              textAlign: header.column.columnDef.align
                                ? header.column.columnDef.align
                                : "left",
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        {loading && data.length === 0 ? (
          <TableRowSkeleton
            columns={finalColumns.length}
            rows={pagingData.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody style={{ verticalAlign: "top" }}>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.columnDef.id === "_idx") {
                      if (showPagination) {
                        return (
                          <Td key={cell.id}>
                            <p className="text-center">
                              {row.index + 1 + (pageIndex - 1) * pageSize}
                            </p>
                          </Td>
                        );
                      } else {
                        return (
                          <Td key={cell.id}>
                            <p className="text-center">{row.index + 1}</p>
                          </Td>
                        );
                      }
                    } else {
                      return (
                        <Td
                          key={cell.id}
                          style={{
                            textAlign: cell.column.columnDef.align
                              ? cell.column.columnDef.align
                              : "left",
                          }}
                          className="text-center"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    }
                  })}
                </Tr>
              );
            })}
            {footerRow && footerRow}
            {/* 
            Example footerRow
            
        footerRow={
          <Tr>
            <Td colSpan="2" align="center" className="font-bold">
              Total
            </Td>
            <Td align="right">
              {_.sumBy(tableData.data, function (o) {
                return o.amount;
              }).toLocaleString("id-ID")}
            </Td>
          </Tr>
        }
            } */}
          </TBody>
        )}
      </Table>
      {showPagination && (
        <div className="flex items-center justify-between mt-4">
          <Pagination
            displayTotal={true}
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
            onChange={handlePaginationChange}
          />
          <div
            style={{ minWidth: 130 }}
            className="flex flex-row justify-end items-center space-x-2"
          >
            <Select
              size="sm"
              menuPlacement="top"
              isSearchable={false}
              value={pageSizeOption.filter(
                (option) => option.value === pageSize
              )}
              options={pageSizeOption}
              onChange={(option) => handleSelectChange(option.value)}
            />
          </div>
        </div>
      )}
    </Loading>
  );
});

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
};

DataTable.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
};

export default DataTable;
