import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { setToken } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import PopNotification from "components/shared/PopNotification";
import Api from "api/auth/UserApi";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const userInfo = useSelector((state) => state.auth.user);

  const signIn = async (values) => {
    try {
      const resp = await Api.login(values);
      if (resp.data) {
        const { token, user } = resp.data.data;
        if (token) {
          dispatch(onSignInSuccess(token));
        }
        if (user) {
          dispatch(setUser(user));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        PopNotification("success", resp.data.status, resp.data.message);
        return {
          status: resp.data.status,
          message: resp.data.message,
        };
      }
    } catch (errors) {
      return {
        status: errors.response.data.status,
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(setToken(null));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut()
    handleSignOut();
  };

  const isAuthority = (val) => {
    return userInfo?.authority.includes(val);
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    isAuthority,
    userInfo,
  };
}

export default useAuth;
