const appConfig = {
  servicePrefix: {
    local: {
      auth: "http://localhost:40010",
      district: "http://localhost:40011",
      file: "http://localhost:40012",
      project: "http://localhost:40013",
      technique: "http://localhost:40013",
      legal: "http://localhost:40014",
      administration: "http://localhost:40013",
      marketing: "http://localhost:40014",
      hrga: "http://localhost:40015",
      finance: "http://localhost:40015",
      accounting: "http://localhost:40015",
    },
    production: {
      auth: "https://api.lebakkinasih.com/auth",
      district: "https://api.lebakkinasih.com/district",
      file: "https://api.lebakkinasih.com/file",
      project: "https://api.lebakkinasih.com/project",
      technique: "https://api.lebakkinasih.com/project",
      administration: "https://api.lebakkinasih.com/project",
      legal: "https://api.lebakkinasih.com/marketing",
      marketing: "https://api.lebakkinasih.com/marketing",
      hrga: "https://api.lebakkinasih.com/finance",
      finance: "https://api.lebakkinasih.com/finance",
      accounting: "https://api.lebakkinasih.com/finance",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "production",
};

export default appConfig;
